import { Helmet } from "react-helmet-async";

import { HeroSection } from "./sections/hero";

export const HomePage = () => {
  return (
    <main className="relative isolate bg-background">
      <Helmet prioritizeSeoTags>
        <html />

        <title>{`AlmaBetter's Resume Builder`}</title>

        <meta
          name="description"
          content="Crafting dreams, one resume at a time – AlmaBetter's Resume Builder: Your key to a standout professional journey!"
        />
      </Helmet>

      <HeroSection />
      {/*<LogoCloudSection />*/}
      {/*<StatisticsSection />*/}
      {/*<FeaturesSection />*/}
      {/*<TemplatesSection />*/}
      {/*<TestimonialsSection />*/}
      {/*<SupportSection />*/}
      {/*<FAQSection />*/}
      {/*<ContributorsSection />*/}
    </main>
  );
};
